export const orphans = () => {
    document.querySelectorAll("span, h2, h3, h1, p").forEach((span) => {
        let tekst = span.innerHTML;
        tekst = tekst.replace(/(\s)([^<,]{1,2})[\s]+/g, "$1$2&nbsp;");
        span.innerHTML = tekst;
    });
}

export const formatDate = (data) => {
    data = data.replace(/[-]/g, ".");
    return data
}

export const formatPhone = (data) => {
    if( data) {
        data = data.replace(/[() ]/g, "");
    }
    return data
}