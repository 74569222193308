import React from "react";

import styles from './personItem.module.scss'
import {Link, useParams} from "react-router-dom";
import slug from "slug";

const PersonItem = (props) => {

    const { lang } = useParams()

    return (
        <div className={styles.itemWrapper}>
            <Link
                className={styles.itemLink}
                to={`/${lang}/osoba/${props.data.id}-${slug(`${props.data.first_name}-${props.data.last_name}`)}`}
                aria-label={`${props.translation.goToPersonPage} "${props.data.first_name} ${props.data.last_name}"`}
                title={`${props.translation.goToPersonPage} "${props.data.first_name} ${props.data.last_name}"`}
            >
                <div className={styles.dataImg}>
                    <div className={styles.imgContainer} style={{backgroundImage: `url("${props.data.image}")`}} />
                </div>
                <div className={styles.dataBox}>
                    <span className={styles.author}>{props.data.first_name} {props.data.last_name}</span>
                </div>
            </Link>
        </div>
    )
}

export default PersonItem