import React from "react";

import styles from './loaderForForms.module.scss'

const LoaderForForms = (props) => {
    return (
        <div className={`${styles.loader} ${props.show ? styles.loaderShow : ''}`} style={props.scale ? { transform: `scale(${props.scale})`} : {}}>
            <div className={styles.loaderContent}/>
        </div>
    )
}

export default LoaderForForms