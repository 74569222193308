import React, {useRef} from "react";

import styles from './modal.module.scss'
import {CloseImage} from "../../assets/img/general/svg";
import translation from "../Helpers/translation";

function Modal(props) {

    const modalContainerRef = useRef();
    const modalRef = useRef();
    const closeRef = useRef()

    const hideModal = (event) => {
        if(event.target === modalRef.current || event.target === closeRef.current || event.target === modalContainerRef.current || event.target.tagName === "svg" || event.target.tagName === "path") {
            if(props.index !== undefined) {
                props.setOpened(false, props.index)
            } else {
                props.setOpened(false)
            }
        }
    }

    return (
        <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} onClick={hideModal}>
            <div ref={modalContainerRef} className={styles.modalContainer} style={props.maxSize ? { maxWidth: `${props.maxSize}px`} : {}}>
                <div className={styles.modal}>
                    <button
                        type={"button"}
                        ref={closeRef}
                        className={styles.close}
                        onClick={hideModal}
                        aria-label={translation.wcag.closeModal}
                        title={translation.wcag.closeModal}
                    >
                        <CloseImage/>
                    </button>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal