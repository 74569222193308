import React, { useEffect, useState } from "react";

import { Loader } from "../../Components/Loader/loader";
import Footer from "../Footer/footer";
import { apiUrls } from "../../assets/api/apiURL";
import { Link, useParams } from "react-router-dom";
import PeopleSlider from "../../Components/Sliders/PeopleSlider/peopleSlider";
import translation from "../../Components/Helpers/translation";
import ShareButtons from "../../Components/ShareButtons/shareButtons";
import ProductItem from "../../Components/ProductItem/productItem";

import styles from './institutionPage.module.scss';


const InstitutionsList = () => {
    const [institutions, setInstitutions] = useState(null);
    const { lang, id } = useParams()
    const [artists, setArtists] = useState(null)

    useEffect(() => {
        fetch(`${apiUrls.mainURL}${apiUrls.institution}?id=${parseInt(id)}&lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setInstitutions(json);
            })
            .catch((error) => {
                console.error("Error fetching institutions:", error);
            });
    }, [lang]);

    useEffect(() => {
        fetch(`${apiUrls.mainURL}${apiUrls.popularArtists}?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setArtists(json)
            });
    }, [lang])

    return (
        <>
            <Loader data={institutions !== null} />
            {institutions !== null ? (
                <div className={styles.personWrapper}>
                    <div className={styles.personContainer}>
                        <div className={styles.content}>
                            <div className={styles.left}>
                                <div style={{ backgroundImage: `url("${institutions.image}")` }} className={styles.peopleImage} />
                            </div>
                            <div className={styles.right}>
                                <p className={styles.country}>{institutions.country_name}</p>
                                <h1 className={styles.name}>{institutions.title} </h1>
                                <div className={styles.text} dangerouslySetInnerHTML={{ __html: institutions.text }} />
                            </div>
                        </div>
                        <div className={styles.shareWrapper}>
                            <ShareButtons />
                        </div>
                        {institutions.products ?
                            <>
                                <h2 className={styles.discographyTitle}>{translation.personPage.discography}</h2>
                                <div className={styles.discographyWrapper}>
                                    {institutions.products.map((item, index) => {
                                        return (
                                            <div className={styles.item} key={index}>
                                                <ProductItem item={item} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                            : null}
                        <div className={styles.peopleContainer}>
                            <div className={styles.box}>
                                <h2 className={styles.title}>{translation.mainPage.artists.title}</h2>
                                <Link
                                    className={styles.showAll}
                                    to={`/${lang}/artysci`}
                                    aria-label={translation.mainPage.wcag.artistSlider.seeAllArtists}
                                    title={translation.mainPage.wcag.artistSlider.seeAllArtists}
                                >
                                    {translation.mainPage.artists.seeAll}
                                </Link>
                            </div>
                            <PeopleSlider data={artists} startNumber={7} translation={translation.mainPage.wcag.artistSlider} />
                            <div className={styles.boxBottom}>
                                <Link
                                    className={styles.showAll}
                                    to={`/${lang}/artysci`}
                                    aria-label={translation.mainPage.wcag.artistSlider.seeAllArtists}
                                    title={translation.mainPage.wcag.artistSlider.seeAllArtists}
                                >
                                    {translation.mainPage.artists.seeAll}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            ) : null}
        </>
    )
}


export default InstitutionsList;
