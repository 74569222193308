import React from "react";

import styles from './shareButtons.module.scss'
import {FacebookImage, TwitterImage} from "../../assets/img/general/svg";
import translation from "../Helpers/translation";
const ShareButtons = () => {

    const share = (type) => {
        let popUpObj
        if(type === 1) {
            popUpObj = window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,"ModalPopUp","width=600, height=600");
        } else if(type === 2) {
            popUpObj = window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`,"ModalPopUp","width=600, height=600");
        }
        popUpObj.focus();
    }

    return (
        <div className={styles.shareWrapper}>
            <button
                type={'button'}
                className={styles.buttonShare}
                onClick={() => share(1)}
                aria-label={translation.wcag.shareFacebook}
                title={translation.wcag.shareFacebook}
            >
                <FacebookImage/>
            </button>
            <button
                type={'button'}
                className={styles.buttonShare}
                onClick={() => share(2)}
                aria-label={translation.wcag.shareTwitter}
                title={translation.wcag.shareTwitter}
            >
                <TwitterImage/>
            </button>
        </div>
    )
}

export default ShareButtons