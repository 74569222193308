export const apiUrls = {
    mainURL: 'https://api-sklep.nifc.pl/',
    homepage: 'pages/home',
    homePageCategories : "products/categories/homepage",
    menu: 'menu',
    products: 'products',
    recommendedProducts : "products/recommended",
    category: 'categories',
    countries : 'countries',
    artists : "people/artists",
    popularArtists : "people/popular-artists",
    authors : "people/authors",
    conductors : "people/conductors",
    institution: "institution",
    person : "people",
    newsletter : "newsletter/add",
    mainPage: {
        topSlider: "pages/home/banner"
    },
    shippingTypes : "shipping",
    paymentsTypes : "payments",
    user: {
        create : 'account/create',
        login : 'auth/login',
        token : "auth/refresh-token",
        logout : "auth/logout",
        lostPassword : "account/reset-password",
        favourites : {
            get : "favourites",
            add : "favourites/add",
            remove : "favourites/remove"
        },
        userPage : {
            accountData : "account",
            addresses : "account/addresses",
            editContact : "account/edit-contact",
            changePassword : "account/change-password",
            addInvoiceAddress : "account/save-invoice-address/",
            editInvoiceAddress : "account/edit-invoice-address/",
            deleteInvoiceAddress : "account/delete-invoice-address/",
            addShippingAddress : "account/save-shipping-address/",
            editShippingAddress : "account/edit-shipping-address/",
            deleteShippingAddress : "account/delete-shipping-address/",
            editAgreements : "account/edit-agreements/"
        },
        orders : "orders",
    },
    cart : {
        cartData : "cart",
        add : "cart/add-product",
        delete : "cart/delete-product",
        update : "cart/update-product"
    },
    textPages : {
        pages : "pages/static",
    },
    checkout : "checkout",
    afterCheckout : "payments/gateway/retrieve-after-payment-data",
    changeCurrency : 'site/change-currency',
    changeLang : "site/change-lang",
    contact : 'config/contact',
    promoProduct : 'config/cart-promo'
};
