import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Newsletter from "../../Components/Newsletter/newsletter";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";

import {
    ArrowImage,
    BlikImage, CloseImage,
    FacebookImage,
    MasterCardImage, PayUImage,
    SiteLogoWhiteImage,
    TwitterImage,
    VisaImage,
    YoutubeImage
} from "../../assets/img/general/svg";

import {ThemeContext} from "../../Components/Context/context";
/*import {orphans} from "../../Components/Helpers/functions";*/

import styles from './footer.module.scss';
import SlideUpDown from "../../Components/Helpers/slideUpDown/slideUpDown";
import FormResetPassword from "../../Components/FormResetPassword/formResetPassword";
import translation from "../../Components/Helpers/translation";
import {formatPhone} from "../../Components/Helpers/functions";

const Footer = () => {
    const { lang } = useParams();
    const size = useWindowSize()
    const [columnClosed1, setColumnClosed1] = useState(true)
    const [columnClosed2, setColumnClosed2] = useState(true)
    const [columnClosed3, setColumnClosed3] = useState(true)

    const [columnClosedStart1, setColumnClosedStart1] = useState(true)
    const [columnClosedStart2, setColumnClosedStart2] = useState(true)
    const [columnClosedStart3, setColumnClosedStart3] = useState(true)

    const [footerMargin, setFooterMargin] = useState(0)

    const [showCookiesAccepted, setShowCookiesAccepted] = useState(false)

    const showContext = useContext(ThemeContext)

    /*useEffect(() => {
        if(location.pathname !== `/${lang}/koszyk`) {
            orphans()
        }
    },[location, lang])*/

    useEffect(() => {
        if(size.width > 450) {
            setColumnClosed1(false)
            setColumnClosed2(false)
            setColumnClosed3(false)

            setColumnClosedStart1(false)
            setColumnClosedStart2(false)
            setColumnClosedStart3(false)
        } else {
            setColumnClosed1(true)
            setColumnClosed2(true)
            setColumnClosed3(true)

            setColumnClosedStart1(true)
            setColumnClosedStart2(true)
            setColumnClosedStart3(true)
        }
    },[size.width])

    /*useEffect(() => {

        const root = document.getElementById("root")

        if (!root.children[2].children[0] && showContext.contact === null) return;
        const resizeObserver = new ResizeObserver(() => {

            if (root.children[2]) {
                const heightHeader = root.children[0].clientHeight
                const heightMain = root.children[2].children[0].clientHeight
                const heightFooter = root.children[2].children[1].clientHeight
                const sum = heightHeader + heightMain + heightFooter

                const siteHeight = window.innerHeight

                if (siteHeight > sum) {
                    setFooterMargin( siteHeight - sum)
                } else {
                    setFooterMargin( 0)
                }
            }

        });
        resizeObserver.observe(root.children[2].children[0]);
        return () => resizeObserver.disconnect(); // clean up
    }, [showContext.contact, size.height, location.pathname]);*/

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted")

        if(!cookiesAccepted) {
            setShowCookiesAccepted(true)
        }
    },[])

    return (
        showContext.contact ?
            <div className={styles.wrapper} style={{marginTop : `${footerMargin}px`}}>
                <div className={`${styles.cookiesAccepted} ${showCookiesAccepted ? styles.show : ''}`}>
                    <p className={styles.text}>
                        {translation.footer.cookies.text} <Link to={`/${lang}/polityka-prywatnosci`}>{translation.footer.cookies.linkText}</Link>
                    </p>
                    <button
                        type={"button"}
                        className={styles.close}
                        onClick={() => {
                            setShowCookiesAccepted(false);
                            localStorage.setItem("cookiesAccepted", "true")
                        }}
                        title={translation.footer.wcag.cookieClose}
                        aria-label={translation.footer.wcag.cookieClose}
                    >
                        <CloseImage/>
                    </button>

                </div>
                <div className={styles.topContainer}>
                    <Link to={`/${lang}`} className={styles.left}>
                        <SiteLogoWhiteImage/>
                    </Link>
                    <div className={styles.middle}>
                        <div className={styles.title}><span>{translation.footer.title[0]}</span> <span>{translation.footer.title[1]}</span></div>
                        <div className={styles.menu}>
                            {showContext.menuData?.map((item, index) => {
                                return (
                                    item.url ?
                                        <Link
                                            to={`/${lang}/${item.url}`}
                                            className={styles.item}
                                            key={index}
                                            aria-label={`${item.bold ? translation.footer.wcag.goToSite : translation.footer.wcag.goToCategory} "${item.name}"`}
                                            title={`${item.bold ? translation.footer.wcag.goToSite : translation.footer.wcag.goToCategory} "${item.name}"`}
                                        >
                                            {item.name}
                                        </Link>
                                        : <div className={styles.separator} key={index}/>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <a
                            href={`https://www.facebook.com/profile.php?id=100069819969917`}
                            className={styles.item}
                            target={"_blank"}
                            rel={"noreferrer"}
                            aria-label={translation.footer.wcag.facebookLink}
                            title={translation.footer.wcag.facebookLink}
                        >
                            <FacebookImage/>
                        </a>
                        <a
                            href={`https://www.youtube.com/channel/UCSTXol20Q01Uj-U5Yp3IqFg`}
                            className={styles.item}
                            target={"_blank"}
                            rel={"noreferrer"}
                            aria-label={translation.footer.wcag.youtubeLink}
                            title={translation.footer.wcag.youtubeLink}
                        >
                            <YoutubeImage/>
                        </a>
                        <a
                            href={`https://twitter.com/chopininstitute`}
                            className={styles.item}
                            target={"_blank"}
                            rel={"noreferrer"}
                            aria-label={translation.footer.wcag.twitterLink}
                            title={translation.footer.wcag.twitterLink}
                        >
                            <TwitterImage/>
                        </a>
                        <a
                            target={"_blank"}
                            rel={"noreferrer"}
                            href={translation.footer.nifcLink.link}
                            className={styles.linkNifc}
                            aria-label={translation.footer.wcag.nifcLink}
                            title={translation.footer.wcag.nifcLink}
                        >
                            {translation.footer.nifcLink.title}
                        </a>
                    </div>
                </div>
                <div className={styles.middleContainer}>
                    <div className={styles.column}>
                        {size.width > 450 ?
                            null
                            : <button type={"button"} className={styles.buttonOpen} onClick={() => setColumnClosed1(!columnClosed1)}>
                                <span>{translation.footer.contact}</span>
                                <div className={`${styles.arrow} ${!columnClosed1 ? styles.opened : ''}`}>
                                    <ArrowImage/>
                                </div>
                            </button>
                        }
                        <SlideUpDown closed={columnClosed1} closedStart={columnClosedStart1}>
                            <div className={styles.box}>
                                {size.width > 450
                                    ? <p className={styles.title}>{translation.footer.contact}</p>
                                    : null
                                }
                                <div className={styles.item}>
                                    <span className={styles.text}>{showContext.contact.header}</span>
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.text}>{showContext.contact.address}</span>
                                    <span className={styles.text}>{showContext.contact.address_two}</span>
                                </div>
                                <div className={styles.item}>
                                    <a
                                        className={styles.link}
                                        href={`tel:${formatPhone(showContext.contact.phone)}`}
                                        aria-label={translation.footer.wcag.phoneLink}
                                        title={translation.footer.wcag.phoneLink}
                                    >
                                        tel.: {showContext.contact.phone}
                                    </a>
                                    <a
                                        className={styles.link}
                                        href={`fax:${formatPhone(showContext.contact.fax)}`}
                                        aria-label={translation.footer.wcag.fakslink}
                                        title={translation.footer.wcag.fakslink}
                                    >
                                        faks: {showContext.contact.fax}
                                    </a>
                                </div>
                                <div className={`${styles.item} ${styles.noMargin}`}>
                                    <a
                                        className={styles.link}
                                        href={`mailto:${showContext.contact.mail}`}
                                        aria-label={translation.footer.wcag.emailLink}
                                        title={translation.footer.wcag.emailLink}
                                    >
                                        e-mail: {showContext.contact.mail}
                                    </a>
                                </div>
                            </div>
                        </SlideUpDown>
                    </div>
                    <div className={styles.column}>
                        {size.width > 450 ?
                            null
                            : <button type={"button"} className={styles.buttonOpen} onClick={() => setColumnClosed2(!columnClosed2)}>
                                <span>{translation.footer.information}</span>
                                <div className={`${styles.arrow} ${!columnClosed2 ? styles.opened : ''}`}>
                                    <ArrowImage/>
                                </div>
                            </button>
                        }
                        <SlideUpDown closed={columnClosed2} closedStart={columnClosedStart2}>
                            <div className={styles.box}>
                                {size.width > 450
                                    ? <p className={styles.title}>{translation.footer.information}</p>
                                    : null
                                }
                                {showContext.textPages.map((item, index) => {
                                    return (
                                        <div className={styles.item} key={index}>
                                            <Link
                                                className={styles.link}
                                                to={`/${lang}/${item.url}`}
                                                aria-label={`${translation.footer.wcag.goToSite} "${item.name}"`}
                                                title={`${translation.footer.wcag.goToSite} "${item.name}"`}
                                            >
                                                {item.name}
                                            </Link>
                                        </div>
                                    )
                                })}
                                {/*<a
                                    href={`/`}
                                    className={styles.button}
                                    aria-label={translation.footer.wcag.stationatyShop}
                                    title={translation.footer.wcag.stationatyShop}
                                >
                                    <span>{translation.footer.stationatyShop}</span>
                                </a>*/}
                            </div>
                        </SlideUpDown>
                    </div>
                    <div className={styles.column}>
                        {size.width > 450 ?
                            null
                        : <button type={"button"} className={styles.buttonOpen} onClick={() => setColumnClosed3(!columnClosed3)}>
                                <span>{translation.footer.accountAndOrders}</span>
                                <div className={`${styles.arrow} ${!columnClosed3 ? styles.opened : ''}`}>
                                    <ArrowImage/>
                                </div>
                            </button>
                        }
                        <SlideUpDown closed={columnClosed3} closedStart={columnClosedStart3}>
                            <div className={styles.box}>
                                {size.width > 450
                                    ? <p className={styles.title}>{translation.footer.accountAndOrders}</p>
                                    : null
                                }
                                {translation.leftMenu.type.user.list.map((item, index) => {
                                    return (
                                        <div className={styles.item} key={index}>
                                            <Link
                                                className={styles.link}
                                                to={`/${lang}/${item.url}`}
                                                aria-label={`${translation.footer.wcag.goToSite} "${item.name}"`}
                                                title={`${translation.footer.wcag.goToSite} "${item.name}"`}
                                            >
                                                {item.name}
                                            </Link>
                                        </div>
                                    )
                                })}
                                <div className={`${styles.item} ${styles.noMargin}`}>
                                    <Link
                                        className={styles.link}
                                        to={`/${lang}/koszyk`}
                                        aria-label={translation.footer.wcag.cartLink}
                                        title={translation.footer.wcag.cartLink}
                                    >
                                        {translation.footer.cart}
                                    </Link>
                                </div>
                            </div>
                        </SlideUpDown>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.box}>
                            <p className={styles.title}>Newsletter</p>
                            <div className={styles.item}>
                                <span className={styles.text}>{translation.footer.newsletterText}</span>
                            </div>
                            <Newsletter footer={true}/>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.copyrights}>{translation.footer.copyrights}</div>
                    <div className={styles.payments}>
                        <div className={styles.dotpay}>
                            <PayUImage/>
                        </div>
                        <div className={styles.visa}>
                            <VisaImage/>
                        </div>
                        <div className={styles.masterCard}>
                            <MasterCardImage/>
                        </div>
                        <div className={styles.blik}>
                            <BlikImage/>
                        </div>
                    </div>
                </div>
                <FormResetPassword/>
            </div>
            : null
    );
};

export default Footer;
