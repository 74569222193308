import React from 'react';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import PersonItem from "../../PersonItem/personItem";

import styles from './peopleSlider.module.scss'
import { ArrowImage } from 'assets/img/general/svg';

const PeopleSlider = (props) => {

    const splideOptions = {
        type: 'slide',
        perPage: props.startNumber,
        arrows: true,
        perMove: 1,
        pagination: false,
        breakpoints: props.startNumber === 7
            ? {
                1200: {
                    perPage: 6,
                },
                1000: {
                    perPage: 5,
                },
                800: {
                    perPage: 4,
                },
                600: {
                    perPage: 3,
                },
                450: {
                    perPage: 2,
                },
            }
            : {
                1150: {
                    perPage: 4,
                },
                900: {
                    perPage: 3,
                },
                800: {
                    perPage: 4,
                },
                600: {
                    perPage: 3,
                },
                500: {
                    fixedWidth: 140,
                    gap: 10
                },
                450: {
                    perPage: 2,
                }
            },
        i18n: {
            prev: props.translation.horizontalPrev,
            next: props.translation.horizontalNext,
            first: props.translation.horizontalPrev,
            last: props.translation.horizontalNext,
            slideLabel: props.translation.slideLabel,
            slideX: props.translation.slideX,
            carousel: ''
        }
    };

    return (
        <Splide hasTrack={false} options={splideOptions} aria-label="" className={styles.slider}>
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.leftArrow}`}>
                    <ArrowImage />
                </button>
                <button className={`splide__arrow splide__arrow--next ${styles.rightArrow}`}>
                    <ArrowImage />
                </button>
            </div>
            <SplideTrack>
                {props.data.map((item, index) => (
                    <SplideSlide key={index}>
                        <PersonItem data={item} translation={props.translation} />
                    </SplideSlide>
                ))}
            </SplideTrack>
        </Splide>
    );
};

export default PeopleSlider;
