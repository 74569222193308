import React, {lazy, Suspense, useContext, useEffect, useRef, useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import '../../assets/styles/index.scss'
import '../../assets/styles/variables.module.scss';
import { Loader } from '../../Components/Loader/loader';
import {apiUrls} from "../../assets/api/apiURL";
import slug from "slug";
import {ThemeContext} from "../../Components/Context/context";
import InstitutionsList from 'Containers/institutionPage/institutionPage';

const Header = lazy(() => import('../Header/header'));
const MainPage = lazy(() => import('../MainPage/mainPage'));
const ProductPage = lazy(() => import('../ProductPage/productPage'));
const ProductsListPage = lazy(() => import('../ProductsListPage/productsListPage'));
const PersonPage = lazy(() => import('../PersonPage/personPage'));
const PeopleListPage = lazy(() => import('../PeopleListPage/peopleListPage'));
const UserRegistrationPage = lazy(() => import('../UserRegistrationPage/userRegistrationPage'));

const LoginPages = lazy(() => import('../LoginPages/loginPages'));
const TextPage = lazy(() => import('../TextPage/textPage'));

const CartPage = lazy(() => import('../OrderPages/CartPage/cartPage'));
const CartPageLogin = lazy(() => import('../OrderPages/CartPageLogin/cartPageLogin'));
const CartPageDelivery = lazy(() => import('../OrderPages/CartPageDelivery/cartPageDelivery'));
const CartPageSummary = lazy(() => import('../OrderPages/CartPageSummary/cartPageSummary'));
const CartPageFinal = lazy(() => import('../OrderPages/CartPageFinal/cartPageFinal'));

const App = () => {

    const lang = window.location.href.split('/')[3];

    const [tekstPages, setTekstPages] = useState(null)

    const showContextRef = useRef(useContext(ThemeContext))

    const tekstPagesWrite = () => {
        let tekst = ''

        tekstPages.forEach((item,index) => {
            if(index !== 0) {
                tekst = tekst + '|' + slug(item.url)
            } else {
                tekst = slug(item.url)
            }
        })

        return tekst
    }

    useEffect(() => {
        fetch(`${apiUrls.mainURL}${apiUrls.textPages.pages}?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                const tmpData = []

                json.forEach((item) => {
                    tmpData.push({url: `${slug(item.url)}`, name: item.title, child: [], id : item.id})
                })

                setTekstPages(tmpData)
                showContextRef.current.updateTextPages(tmpData)
            })
    },[lang])

    return (
        <Suspense fallback={<Loader builder={true} />}>
            {tekstPages ?
                <Switch>
                    <Route exact path={'/:lang(pl|en)'}>
                        <Header />
                        <MainPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/produkt/:id'}>
                        <Header />
                        <ProductPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/:type(kategoria|grupa)/:id'}>
                        <Header />
                        <ProductsListPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/wyszukiwanie'}>
                        <Header />
                        <ProductsListPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/promocje'}>
                        <Header />
                        <ProductsListPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/:type(autorzy|artysci|autorzy-redaktorzy|dyrygenci|instytucje)/'}>
                        <Header />
                        <PeopleListPage/>
                    </Route>
                    <Route exact path={'/:lang(pl|en)/osoba/:id'}>
                        <Header />
                        <PersonPage/>
                    </Route>
                    <Route exact path={'/:lang(pl|en)/instytucja/:id'}>
                        <Header />
                        <InstitutionsList/>
                    </Route>
                    <Route exact path={'/:lang(pl|en)/rejestracja/'}>
                        <Header />
                        <UserRegistrationPage />
                    </Route>
                    <Route path={'/:lang(pl|en)/uzytkownik/'}>
                        <Header />
                        <LoginPages/>
                    </Route>
                    <Route exact path={`/:lang(pl|en)/:url(${tekstPagesWrite()})/`} >
                        <Header/>
                        <TextPage data={tekstPages}/>
                    </Route>
                    <Route exact path={'/:lang(pl|en)/koszyk'}>
                        <Header />
                        <CartPage />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/zamowienie/logowanie-rejestracja'}>
                        <Header />
                        <CartPageLogin />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/zamowienie/dostawa'}>
                        <Header />
                        <CartPageDelivery />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/zamowienie/podsumowanie'}>
                        <Header />
                        <CartPageSummary />
                    </Route>
                    <Route exact path={'/:lang(pl|en)/zamowienie/zakceptowane'}>
                        <Header />
                        <CartPageFinal />
                    </Route>

                    <Route exact path={'*'}>
                        {lang === 'en'
                            ? <Redirect to={`/en`} />
                            : <Redirect to={'/pl'} />
                        }
                    </Route>
                </Switch>
            : null}
        </Suspense>
    );
};

function Builder() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default Builder;
