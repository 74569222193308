import React, {useRef, useState} from 'react';
import styles from './newsletter.module.scss';

import {useFormik} from "formik";
import translation from "../Helpers/translation";
import {apiUrls} from "../../assets/api/apiURL";
import LoaderForForms from "../LoaderForForms/loaderForForms";

const Newsletter = (props) => {

    const messageRef = useRef()
    const [showLoader, setShowLoader] = useState(false)

    const validationNewsletter = (values) => {
        const errors = {}

        if (!values.email) {
            errors.email = translation.newsletter.inputs.email.errors[0];
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = translation.newsletter.inputs.email.errors[1];
        }

        if (!values.agreement) {
            errors.agreement = translation.newsletter.inputs.agreement.errors[0];
        }

        return errors
    }


    const formikNewsletter = useFormik({
        initialValues: {
            email: '',
            agreement: false,
        },
        validateOnChange: false,
        validate: validationNewsletter,
        onSubmit: (values, actions) => {
            setShowLoader(true)

            const language = window.navigator.language || window.navigator.userLanguage;
            const formData = new FormData();

            formData.append("email", values['email']);
            formData.append("lang", language);

            fetch(`${apiUrls.mainURL}${apiUrls.newsletter}`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                cache: 'no-cache',
                body: formData
            })
                .then((res) => res.json())
                .then((json) => {
                    if(json.success) {
                        messageRef.current.innerText = translation.newsletter.formMessages.success
                    } else {
                        messageRef.current.innerText = translation.newsletter.formMessages.failure
                    }
                    messageRef.current.classList.add(styles.show)

                    setTimeout(() => {
                        setShowLoader(false)
                        messageRef.current.classList.remove(styles.show)
                    },3000)
                })
        }
    });

    return (
        <div className={`${styles.mainWrapper} ${props.footer ? styles.mainWrapperFooter : ''}`}>
            {!props.footer ?
                <>
                    <div className={styles.title}>{translation.newsletter.title}</div>
                    <div className={styles.lead}>{translation.newsletter.text}</div>
                </>
            : null}
            <div className={styles.wrapper}>
                <div className={`${styles.loader} ${showLoader ? styles.show : ''}`}>
                    <LoaderForForms show={showLoader}/>
                    <p className={styles.message} ref={messageRef}></p>
                </div>
                <form className={styles.form} onSubmit={formikNewsletter.handleSubmit}>
                    <div className={styles.inputContainer}>
                        <label className={styles.inputBox}>
                            <input
                                name={'email'}
                                type={'text'}
                                placeholder={translation.newsletter.inputs.email.placeholder}
                                onChange={formikNewsletter.handleChange}
                                className={`${styles.input} ${formikNewsletter.errors.email ? styles.error : ''}`}
                                aria-label={translation.newsletter.wcag.inputText}
                                title={translation.newsletter.wcag.inputText}
                            />
                        </label>
                        <button
                            className={styles.button}
                            type={"submit"}
                            aria-label={translation.newsletter.wcag.sendButton}
                            title={translation.newsletter.wcag.sendButton}
                        >
                            <span>{translation.newsletter.button}</span>
                        </button>
                        <div className={styles.errorText}>
                            {formikNewsletter.errors['email']}
                        </div>
                        <div className={styles.policyContainer}>
                            <label>
                                <input
                                    name={'agreement'}
                                    className={`${styles.checkbox} ${formikNewsletter.errors.agreement ? styles.error : ''}`}
                                    checked={formikNewsletter.values.acceptedTos}
                                    type={'checkbox'}
                                    onChange={formikNewsletter.handleChange}
                                    aria-label={translation.newsletter.wcag.agreement}
                                    title={translation.newsletter.wcag.agreement}
                                />
                                <span>{translation.newsletter.inputs.agreement.text}</span>
                            </label>
                            <div className={styles.errorText}><span>{formikNewsletter.errors['agreement']}</span></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};



export default Newsletter;
