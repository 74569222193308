import React, {useContext, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import slug from "slug";
import styles from './productItem.module.scss';
import {FavoriteFullIcon} from "../../assets/img/general/svg";
import LoaderForForms from "../LoaderForForms/loaderForForms";
import translation from "../Helpers/translation";
import {ThemeContext} from "../Context/context";

const ProductItem = ({ item, removeFromFavorite }) => {

    const { lang } = useParams();
    const [showLoader, setShowLoader] = useState(false)

    const showContext = useContext(ThemeContext)

    return (
        <div className={`${styles.wrapper}`}>
            <Link
                to={`/${lang}/produkt/${item.id}-${item.name ? slug(item.name) : ''}`}
                className={styles.shadowWrapper}
                aria-label={`${translation.productsListPage.item.wcag.goTo} "${item.name}"`}
                title={`${translation.productsListPage.item.wcag.goTo} "${item.name}"`}
            >
                <div className={styles.dataImg}>
                    <div className={`${styles.imgContainer} ${item.quantity === 0 || item.quantity === null ? styles.imgSaturation : ''}`} style={{backgroundImage: `url("${item.thumbnail_image}")`}} />
                    {item.promo_percentage
                        ? <p className={styles.discount}>{translation.productsListPage.item.discount}</p>
                        : null
                    }
                </div>
                <div className={styles.dataBox}>
                    <p className={styles.author}>{item.author}</p>
                    <p className={styles.name}>{item.name}</p>
                    <div className={styles.row}>
                        <div className={styles.left}>
                            {item.promo_percentage
                                ? <p className={styles.price}>{showContext.currency === "PLN" ? item.promo_price_PLN : item.promo_price_EUR} {showContext.currency}</p>
                                : null
                            }
                            <p className={`${styles.price} ${item.promo_percentage ? styles.priceDiscount : ''}`}>{item[showContext.currency]} {showContext.currency}</p>
                        </div>
                        {item.quantity === 0 || item.quantity === null
                            ? <p className={styles.notAvailable}>{translation.productsListPage.item.notAvailable}</p>
                            : null
                        }
                    </div>
                    {item.promo_percentage
                        ? <p className={styles.pastPrice}>{translation.productsListPage.item.pastPrice} {showContext.currency === "PLN" ? item.pastPrices.price_pln : item.pastPrices.price_eur}&nbsp;{showContext.currency}</p>
                        : null
                    }
                </div>
            </Link>
            {removeFromFavorite ?
                <>
                    <button
                        type={"button"}
                        className={styles.favorite}
                        onClick={() => {removeFromFavorite(item,setShowLoader); setShowLoader(true)}}
                        aria-label={`${translation.loginPages.userFavoritePage.wcag.deleteFromFavorite[0]} "${item.name}" ${translation.loginPages.userFavoritePage.wcag.deleteFromFavorite[1]}`}
                        title={`${translation.loginPages.userFavoritePage.wcag.deleteFromFavorite[0]} "${item.name}" ${translation.loginPages.userFavoritePage.wcag.deleteFromFavorite[1]}`}
                    >
                        <FavoriteFullIcon/>
                    </button>
                    <div className={`${styles.loader} ${showLoader ? styles.active : ''}`}>
                        <LoaderForForms show={showLoader}/>
                        <span className={styles.text}>
                            {translation.productsListPage.item.removeMessage}
                        </span>
                    </div>
                </>
            : null}
        </div>
    );
};

export default ProductItem;
