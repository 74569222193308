import React, {useContext, useRef, useState} from "react";
import Modal from "../Modal/modal";
import {ThemeContext} from "../Context/context";
import translation from "../Helpers/translation";
import {useFormik} from "formik";
import {apiUrls} from "../../assets/api/apiURL";

import styles from './formResetPassword.module.scss'
import LoaderForForms from "../LoaderForForms/loaderForForms";

const FormResetPassword = () => {

    const messageRef = useRef()
    const [showLoader, setShowLoader] = useState(false)

    const showContext = useContext(ThemeContext)

    const validateResetPassword = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = translation.resetPasswordForm.inputs.email.errors[0];
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = translation.resetPasswordForm.inputs.email.errors[1];
        }

        return errors;
    };

    const formikResetPassword = useFormik({
        initialValues: {
            email: ''
        },
        validateOnChange: false,
        validate: validateResetPassword,
        onSubmit: (values, actions) => {
            setShowLoader(true)

            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
                formData.append(key, value);
            }

            setTimeout(() => {
                fetch(`${apiUrls.mainURL}${apiUrls.user.lostPassword}`, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    cache: 'no-cache',
                    body: formData
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if(json.success) {
                            messageRef.current.innerText = translation.resetPasswordForm.formMessages.success
                        } else {
                            messageRef.current.innerText = translation.resetPasswordForm.formMessages.failure
                        }
                        messageRef.current.classList.add(styles.show)

                        setTimeout(() => {
                            setShowLoader(false)
                            messageRef.current.classList.remove(styles.show)
                        },3000)
                    })
            },100)
        },
    });

    return (
        <Modal opened={showContext.modalResetPasswordOpened} setOpened={showContext.updateModalResetPasswordOpened} maxSize={600}>
            <form onSubmit={formikResetPassword.handleSubmit} className={styles.formWrapper}>
                <div className={`${styles.loader} ${showLoader ? styles.show : ''} `}>
                    <LoaderForForms show={showLoader}/>
                    <p className={styles.message} ref={messageRef}></p>
                </div>
                <p className={styles.title}>{translation.resetPasswordForm.title}</p>
                <label>
                    <p className={styles.inputTitle}>{translation.resetPasswordForm.inputs.email.text}</p>
                    <input
                        className={styles.input}
                        name={'email'}
                        type={'text'}
                        onChange={formikResetPassword.handleChange}
                        value={formikResetPassword.values['email']}
                        aria-label={translation.resetPasswordForm.wcag.emailLabel}
                        title={translation.resetPasswordForm.wcag.emailLabel}
                    />
                    <span className={styles.error}>{formikResetPassword.errors['email']}</span>
                </label>
                <button
                    type={"submit"}
                    className={styles.submitButton}
                    aria-label={translation.resetPasswordForm.wcag.resetButton}
                    title={translation.resetPasswordForm.wcag.resetButton}
                >
                    <span>{translation.resetPasswordForm.button}</span>
                </button>
            </form>
        </Modal>
    )
}

export default FormResetPassword